import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyContent = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Privacy Policy')}
          lang={pageContext.lang}
          meta={[
            {
              property: `robots`,
              content: 'noindex,nofollow',
            },
          ]}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Privacy Policy')}
            descriptionText={t(
              'How do we handle your privacy, which services do we use, which rights do you have?'
            )}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <PrivacyPolicyContent>
                <p>
                  Your privacy is important to us. Psono follows some
                  fundamental principles:
                </p>
                <ul>
                  <li>
                    We do not ask for any information that we do not really need
                  </li>
                  <li>
                    We do not share your information with anyone except to
                    comply with the German law, protect our rights and develop
                    our product.
                  </li>
                </ul>
                <p>
                  {' '}
                  Your personal data is processed by us only in accordance with
                  the German data privacy law. The following passages describes
                  the type and purpose of the use of the gathered personal data.
                  This privacy policy only applies to “psono.com” and no other
                  linked domains.
                </p>
                <br />
                <p>
                  <b>Contact Details:</b>
                </p>
                <p>
                  If you are looking to contact the body that is responsible for
                  the data privacy then please reach out to:
                </p>
                <p>
                  esaqa GmbH
                  <br />
                  Tiergartenstr. 13
                  <br />
                  91247 Vorra
                  <br />
                  Germany
                  <br />
                  <br />
                  Managing Director: Sascha Pfeiffer
                  <br />
                  Commercial Register: Nuremberg Registry Court HRB 37978
                  <br />
                  USt-IdNr.: DE333156173
                  <br />
                  E-mail: support@esaqa.com
                  <br />
                </p>
                <p>
                  <b>Personal Data</b>
                </p>
                <p>
                  {' '}
                  Any data (name, address, billing information, IP-address, …)
                  is only used to fulfill the service and without a legal basis
                  or your explicit consent, not given to any third parties.{' '}
                </p>
                <p>
                  {' '}
                  Psono.com collects personal-identifying information like for
                  example the IP address while using our service, to ensure the
                  service availability and to protect your account. Psono may
                  use that information for statistical analysis and may display
                  this anonymized statistical analysis publicly or provide it to
                  others.{' '}
                </p>
                <p>
                  Account holders in addition must specify some additional
                  information during registration, like for example their e-mail
                  address. That information is used to prevent service
                  disruptions and to inform users about security relevant
                  information and provide services like password reset or to
                  contact the user in case of new invoices or failed payments.
                </p>
                <br />
                <p>
                  <b>What User Data We Collect</b>
                </p>
                <p>
                  When you visit the website, we may collect the following data:
                </p>
                <ul>
                  <li>
                    <p>Your IP address</p>
                  </li>
                  <li>
                    <p>Your browser / OS and version</p>
                  </li>
                  <li>
                    <p>Your language</p>
                  </li>
                  <li>
                    <p>Date and time of the request</p>
                  </li>
                  <li>
                    <p>Requested resource / website</p>
                  </li>
                  <li>
                    <p>Transfered data</p>
                  </li>
                  <li>
                    <p>Status code of the request</p>
                  </li>
                  <li>
                    <p>Website who refered you</p>
                  </li>
                  <li>
                    <p>Your contact information and email address</p>
                  </li>
                  <li>
                    <p>
                      Data profile regarding your online behavior on our website
                    </p>
                  </li>
                </ul>
                <p>
                  If you order a product or a service we collect in addition the
                  following data:
                </p>
                <ul>
                  <li>
                    <p>Name</p>
                  </li>
                  <li>
                    <p>Address</p>
                  </li>
                  <li>
                    <p>Payment information</p>
                  </li>
                </ul>
                <br />
                <p>
                  <b>Why We Collect Your Data</b>
                </p>
                <p>We are collecting your data for several reasons:</p>
                <ul>
                  <li>
                    <p>To better understand your needs.</p>
                  </li>
                  <li>
                    <p>To improve our services and products.</p>
                  </li>
                  <li>
                    <p>To protect our system against attacks and fraud</p>
                  </li>
                  <li>
                    <p>To be able to invoice services proper</p>
                  </li>
                </ul>
                <br />
                <p>
                  <b>Your rights</b>
                </p>
                <p>You have the followinng rights about your personal data:</p>
                <ul>
                  <li>
                    <p>Right to be informed</p>
                  </li>
                  <li>
                    <p>Right of access</p>
                  </li>
                  <li>
                    <p>Right to rectification</p>
                  </li>
                  <li>
                    <p>Right to erasure</p>
                  </li>
                  <li>
                    <p>Right to restrict processing</p>
                  </li>
                  <li>
                    <p>Right to data portability</p>
                  </li>
                  <li>
                    <p>Right to object</p>
                  </li>
                  <li>
                    <p>
                      Rights related to automated decision making including
                      profiling
                    </p>
                  </li>
                </ul>
                <p>
                  You further have the right to make a complaint to the ICO or
                  another supervisory authority
                </p>
                <br />
                <h2>Services</h2>
                <p>Services that we use and that store and handle your data</p>
                <br />
                <p>
                  <b>Browser Storage</b>
                </p>
                <p>
                  {' '}
                  For technical reason (login, sessions, ...) we are using
                  browser storage (e.g. cookies, local storage, ...), which
                  stores data in your browser.
                </p>
                <br />

                <p>
                  <b>Google Analytics</b>
                </p>
                <p>
                  {' '}
                  This website uses Google Analytics (GA) (by Google Inc), to
                  analyse customer behaviour on the website. GA for that reason
                  uses cookies to identify and track users and user behaviour.
                  GA offers the possibility to opt out of this data collection
                  by installing a browser plug-in here:{' '}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    rel="nofollow"
                  >
                    http://tools.google.com/dlpage/gaoptout?hl=en
                  </a>{' '}
                  Psono uses the server side tag manager, which truncate IPs
                  before processing them to prevent direct associations to
                  persons.
                </p>
                <br />

                <p>
                  <b>Brevo</b>
                </p>
                <p>
                  {' '}
                  This website is using Brevo (formerly Sendinblue) as a service
                  to handle all email traffic, including newsletter,
                  registration emails, support emails and potential security
                  notifications. Whenever a user uses our service or subscribes
                  to our newsletter, the email address is handled by Brevo in
                  accordance with{' '}
                  <a
                    href="https://www.brevo.com/legal/termsofuse/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Brevo's terms of use
                  </a>{' '}
                  .
                </p>
                <br />
                <p>
                  <b>Freshdesk</b>
                </p>
                <p>
                  {' '}
                  This website uses Freshdesk to handle support requests.
                  Whenever you are opening a new ticket, via email or support
                  form it will be processes by Freshdesk including all
                  information that you may provide in the process (IP, email
                  address, all data of your support request, ...).
                </p>
                <br />
                <p>
                  <b>Cloudflare</b>
                </p>
                <p>
                  Psono is using Cloudflare to protect it's server from DDoS
                  attacks, offer reduced load times for users and decrease the
                  general server load. Requests are proxied by servers that are
                  closest to the user. An overview of Cloudflares data privacy
                  commitment can be found here in{' '}
                  <a
                    href="https://www.cloudflare.com/privacypolicy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cloudflare's privacy policy
                  </a>
                </p>
                <br />
                <p>
                  <b>Google Cloud Platform</b>
                </p>
                <p>
                  {' '}
                  This website's backend services are hosted by Google on the
                  Google Cloud Platform. Google itself has the highest standards
                  of privacy. Yet as the platform provider, Google has access to
                  all information that the application itself has access to
                  (e.g. your ip, email address, browser information, ...).
                  <br />
                  More information about how Google handles informations and
                  what measures have been applied can be found for example in
                  Google's{' '}
                  <a
                    href="https://cloud.google.com/terms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and Google's{' '}
                  <a
                    href="https://cloud.google.com/terms/data-processing-terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Processing and Security Terms
                  </a>
                </p>
                <br />
                <p>
                  Psono may update its privacy statement from time to time and
                  we encourage everyone to check regularly. It has last been
                  updated May 20th, 2024.
                </p>
              </PrivacyPolicyContent>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
